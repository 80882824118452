<template>
  <crud-form
    :schema="schema"
    :model-id="id"
    :path="'mail-subscriber'"
    :extra-query-string="'join=groups'"
    :form-title="$t('editSubscriber')"
    :redirect-route="'SubscribersList'"
  />
</template>

<script>
  import CrudForm from "../../components/CrudForm";
  import { schema } from './schema/subscriber'

  export default {
    name: 'UpdateSubscriber',
    components: {
      CrudForm
    },
    props: ['id'],
    data: () => {
      return {
        schema,
      }
    },
  }
</script>
