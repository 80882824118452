import {APIService} from "@/services/api";
import i18n from "@/plugins/i18n";

export const uniqueSubscriberEmailValidator = (value, field, model) => {
  return new Promise(async (resolve, reject) => {
    if (value) {
      try {
        const subscribers = await APIService.get(`mail-subscriber?filter=email||$eq||${encodeURI(value)}`);
        if (subscribers.length > 0) {
          if (subscribers[0].id !== model.id) {
            resolve([i18n.t('EmailAlreadyExists', {email: value})]);
          }
        } else {
          resolve();
        }
      } catch (e) {
        console.error(e)
        reject()
      }
    }
  });
};
